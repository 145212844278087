import React from "react";
import { Link } from "gatsby";

import MapContainer from "./map.js";

class CTA extends React.Component {
  render() {
    return (
      <>
        <div
          id="cta"
          className="columns"
          style={{
            margin: "0px",
            padding: "0px",
            textAlign: "left",
            backgroundImage: "url(/img/t1.jpg)",
            backgroundAttachment: "fixed",
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: "400px",
            position: "relative",
            zIndex: "2"
          }}
        >
          <div className="column" id="cta-col">
            <h2
              className="title"
              style={{ fontSize: "2em", fontWeight: "600", color: "#00ff00" }}
            >
              Postaw na <b>nowoczesne</b>
              <br /> techologie już dziś!
            </h2>
            <h3 className="subtitle" style={{ color: "white" }}>
              Zapraszamy do wspólnej rozmowy...
            </h3>
            <div style={{ margin: "auto", padding: "10px" }}>
              <Link
                className="button rexer-button is-size-4"
                style={{
                  border: "1px solid #00ff00",
                  backgroundColor: "rgba(0,0,0,0)",
                  textTransform: "uppercase",
                  color: "#00ff00"
                }}
                to="/kontakt/"
                aria-label="kontakt"
              >
                Kontakt
              </Link>
            </div>
          </div>
          <div className="column is-half cta-map" style={{ padding: "0px" }}>
            <MapContainer />
          </div>
        </div>
      </>
    );
  }
}

export default CTA;
