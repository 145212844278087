import React from "react"

function OfferDetails() {
  return (
<>
<div id="odets" style={{margin:'0% 5%',maxWidth:'90%'}}>
<div>
<div className="columns" style={{maxWidth:'95%'}}>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/centralne_ogrzewanie_b.svg"
width="50px" height="auto" />
</div>
<span>•	Centralne ogrzewanie <br />•	Ciepło technologiczne</span>
 </div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/instalacje_wody_cieplej_cyrk_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Instalacje wody zimnej <br />•	Instalacje wody ciepłej i cyrkulacji </span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/instalacje_hydrantowe_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Instalacje hydrantowe<br />•	Instalacje tryskaczowe</span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/sieci_b.svg"
width="50px" height="auto" />
</div>
<span>•	Sieci wodne<br />•	Sieci kanalizacyjne  <br />•	Sieci grzewcze<br />•	Sieci gazowe</span>
 </div>

<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/wentylacja_b.svg"
width="50px" height="auto" />
</div>
<span>•	Wentylacja mechaniczna <br />•	Wentylacja pożarowa <br />•	Oddymianie</span>
 </div>

</div>


<div className="columns" style={{maxWidth:'95%'}}>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/kanalizacja_b.svg"
width="50px" height="auto" />
</div>
<span>•	Kanalizacja sanitarna <br />•	Kanalizacja technologiczna </span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/kanalizacja_podcisnieniowa_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Kanalizacja podciśnieniowa <br />•	Kanalizacja deszczowa </span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/klimatyzacje_b.svg"
width="50px" height="auto" />
</div>
<span>•	Klimatyzacja precyzyjna <br />•	Instalacje SPLIT <br />•	Instalacje MULTI-SPLIT <br />•	Instalacje VRF <br />•	Woda lodowa</span>
</div>

<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/sprezone_powietrze_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Sprężone powietrze </span>
</div>

<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/para_technologiczna_b.svg"
width="50px" height="auto" />
</div>
<span>•	Gazy techniczne<br />•	Para technologiczna<br />•	Instalacje przemysłowe (m.in. azot, etylen, itp.) </span>
 </div>

</div>


<div className="columns" style={{maxWidth:'95%'}}>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/kotlownie_gazowe_b2.svg"
width="50px" height="auto" />
</div>
<span>•	Kotłownie gazowe<br />•	Kotłownie olejowe<br />•	Kotłownie parowe<br />•	Węzły ciepła<br />•	Węzły chłodu<br /></span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/pomiary_i_regulacje_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Pomiary i regulacje instalacji</span>
</div>
<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/instalacje_gazowe_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Instalacje gazowe </span>
</div>

<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/automatyka_b.svg"
width="50px" height="auto" />
</div>
<span>• Automatyka i BMS</span>
</div>

<div className="column is-one-fifth" style={{margin:'10px',border:'1px solid black',textAlign:'center'}}>
<div style={{textAlign:'center',marginBottom:'10px'}}>
<img alt="Rexer - inżynieria środowiska" loading="lazy" style={{marginBottom:'0px'}} src="/img/o-icons/pompy_ciepla_b1.svg"
width="50px" height="auto" />
</div>
<span>•	Pompy ciepła i odnawialne źródła energii</span>
</div>

</div>
</div>
</div>
</>
)
}
export default OfferDetails
